/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDeduction = /* GraphQL */ `
  mutation CreateDeduction($input: CreateDeductionInput!) {
    createDeduction(input: $input) {
      SubmissionDate
      EmployeeNumber
      PayrollPeriodEndDate
      Amount
      __typename
    }
  }
`;
export const deleteDeduction = /* GraphQL */ `
  mutation DeleteDeduction($input: CreateDeductionInput!) {
    deleteDeduction(input: $input) {
      SubmissionDate
      EmployeeNumber
      PayrollPeriodEndDate
      Amount
      __typename
    }
  }
`;
export const createSFTPFile = /* GraphQL */ `
  mutation CreateSFTPFile($input: ByDateInput!) {
    createSFTPFile(input: $input) {
      Key
      Body
      __typename
    }
  }
`;
export const updateSFTPImportDate = /* GraphQL */ `
  mutation UpdateSFTPImportDate($input: ByDateInput!) {
    updateSFTPImportDate(input: $input) {
      SFTPImportDate
      __typename
    }
  }
`;
